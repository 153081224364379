import React, { useContext, useEffect, useState } from 'react'
import BottomBar from '../layouts/BottomBar'
import { AuthContext } from '../contexts/AuthContext';
import secureLocalStorage from 'react-secure-storage';
import { primaryColor } from '../helper/Constant';
import { apiMyAttendance } from '../api/HomeApi';
import { AttendanceItem } from '../components/AttendanceItem';
import { LoadingScreen } from './LoadingScreen';


export const Beranda = () => {
  const [loading_screen, set_loading_screen] = useState(false);
  const authContext = useContext(AuthContext);
  const [selectedId, setSelectedId ] = useState(null);

  const [employee_photo, set_employee_photo] = useState('')
  const [myattendance_data, setmyattendance_data] = useState([]);

  useEffect( () => {
    console.log('authContext.isLoggedIn', authContext.isLoggedIn)

    const empl_photo = secureLocalStorage.getItem('employee_photo');

    set_employee_photo(empl_photo);

    getApiMyAttendance()
  }, [])

  const getApiMyAttendance = async () => {
    set_loading_screen(true);
    const response = await apiMyAttendance();
    set_loading_screen(false);
    if (response.data.code === "200") {
      console.log('response.data.myattendance', response.data.myattendance)
        setmyattendance_data(response.data.myattendance)
    } else {
        alert(response.data.message)
    }
}

  if (loading_screen) {
    return (
      <LoadingScreen />
    )
  }

  return (
    <>
        <div
          style={{
            // height: '100vh',
          }}
        >
          <div className='sticky-top' style={{
            backgroundColor: primaryColor,
            padding: 16,
            borderBottomLeftRadius: 30,
            borderBottomRightRadius: 30,
          }}>
            <div className='row mt-3 mb-3'>
              <div className='col-3  col-md-3'>
                <img src={`data:image/jpeg;base64,${employee_photo}`} style={{ height: 80, borderRadius: 50,}} />
              </div>
              <div className='col-9 col-md-9'>
                <div className='text-white fs-5 fw-bold'>{ secureLocalStorage.getItem('employee_name')  }</div>
                <div className='text-white fs-6'>{ secureLocalStorage.getItem('company_name')  }</div>
                <div className='text-white'>{ secureLocalStorage.getItem('login')  }</div>
              </div>
            </div>
          </div>

          <div style={{
            height: '100vh',
            overflowY: 'scroll',
            paddingBottom: 70,
          }}>
            {
              myattendance_data.length> 0?
              myattendance_data.map( (item,i) => {
                  const backgroundColor = item.id === selectedId ? 'coral' : primaryColor;
                  const color = item.id === selectedId ? primaryColor : (item.late_check_in>1?'red':'black');
                  const colorLate =  'red';
                  return (
                      <AttendanceItem 
                          id={item.check_in}
                          jam_masuk={item.check_in}
                          jam_pulang={item.checkout}
                          late={item.late_check_in}
                          ci_image={item.x_ci_image}
                          co_image={item.x_co_image}
                      />
                  );
              })

              :
              <>
              <h2>Selamat datang di Dashboard!</h2>
              </>
            }
          </div>
        </div>
        <BottomBar />
    </>
  )
}
