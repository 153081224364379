import React, { useEffect, useState } from 'react'
import Camera from '../components/Camera'
import CameraSenyum from '../components/CameraSenyum'
import CameraFace from '../components/CameraFace'
import {OpenMapContent, getAddressFromCoordinates} from '../components/map/OpenMapContent'
import { LoadingScreen } from './LoadingScreen'
import { AppButton } from '../components/AppButton'
import TopBar from '../layouts/TopBar'
import { useNavigate } from 'react-router-dom'
import { MAPI, version_apk } from '../helper/Constant'
import secureLocalStorage from 'react-secure-storage'
import axios from 'axios'
import { AttendFormat } from '../helper/GlobalFunction'
import { apiGetAllContactRadius } from '../api/AttendanceApi'

export const Absen = () => {
  const [show_map, set_show_map] = useState(false);
  const nav = useNavigate();
  const [user_position, set_user_position] = useState([0,0])
  const [permision_location, set_permision_location] = useState(false);

  const [cur_lat, set_cur_lat] = useState(null);
  const [cur_lon, set_cur_lon] = useState(null);

  const [login, set_login] = useState('');
  const [password, set_password] = useState('');
  const [ attendance_state, setattendance_state] = useState([]);
  const [ attendance_last_check_in, setattendance_last_check_in] = useState('');
  const [ attendance_last_check_out, setattendance_last_check_out] = useState('');
  const [attendance_distance, setattendance_distance] = useState(0);
  const [attendance_latoffice, setattendance_latoffice] = useState(0);
  const [attendance_lonoffice, setattendance_lonoffice] = useState(0);
  const [attendance_array, setattendance_array] = useState([]);
  const [location, setLocation] = useState(null);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [locationDisplayName, setLocationDisplayName] = useState('');
  const [TypeCheck, setTypeCheck] = useState('');
  const [errorMsg, setErrorMsg] = useState(null);
  const [hasPermisionLocation, setHasPermisionLocation] = useState('');
  const [data_contact_radius, set_data_contact_radius] = useState([]);

  const [checkedMocked, setCheckedMocked] = useState(null);

  useEffect( () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        console.log("Latitude is :", position.coords.latitude);
          console.log("Longitude is :", position.coords.longitude);
          set_user_position([
            position.coords.latitude,
            position.coords.longitude,
          ])

          set_cur_lat(position.coords.latitude);
          set_cur_lon(position.coords.longitude);
          getKordinat(position.coords.latitude, position.coords.longitude)

          set_show_map(true);
          set_permision_location(true);

          // START HERE
          attncheck();
      }, (error) => {
          set_permision_location(false);
          console.log(error);
      });
    } else {
      console.log('Geolocation is not supported by this browser.');
    }
  }, [])

  let text = 'Waiting..';
  if (errorMsg) {
    text = errorMsg;
  } else if (location) {
    text = (location);
    var lat = text.coords.latitude
    var lon = text.coords.longitude
    var mocked = text.mocked
  }

  const handleGetAllContactRadius = async (objData) => {
    const res = await apiGetAllContactRadius(objData);
    console.log('handleGetAllContactRadius', res);
    return false;

    // console.log('handleGetAllContactRadius', res.data.code);
    if ( res.data.code == '200' ) {
      set_data_contact_radius(res.data.data);
    }
}

  function handlePermission() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        set_user_position([
          position.coords.latitude,
          position.coords.longitude,
        ])
      }, (error) => {
        console.log(error);
      });
    } else {
      console.log('Geolocation is not supported by this browser.');
    }
  }

  const getKordinat = async (lat,long) => {
    const res = await getAddressFromCoordinates(lat,long);
    setLocationDisplayName(res);
    
  }

  const handleCheckin = () => {
    if (attendance_state === "checked_in") {
      alert("You has been checked in");
      set_show_map(true)
      return false;
    }


    // if (checkedMocked) {
    //   alert("Please Turn Off Your F GPS") 
    //   set_show_map(false);
    //   return false;
    // }

    cekLocationAgain('checkin');
    
  }

  const handleCheckout = () => {
    // nav('/absen-camera', {
    //   state: {
    //     type: 'checkout',
    //   }
    // })

    if (attendance_state === "checked_out") {
      alert("You has been checked out");
      set_show_map(true)
      return false;
    }


    // if (checkedMocked) {
    //   alert("Please Turn Off Your F GPS") 
    //   set_show_map(false);
    //   return false;
    // }

    cekLocationAgain('checkOut');
  }

  const attncheck = async (login_x, password_x) => {
    // set_show_map(true)
    const login_xx = await secureLocalStorage.getItem('login');
    const password_xx = await secureLocalStorage.getItem('password');
    set_login(login_xx);
    set_password(password_xx);

    axios({
      method: 'post',
      url: MAPI+'/attncheck',
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        email: login_xx,
        password: password_xx,
        latitude: latitude,
        longitude: longitude,
        version:version_apk,

      }
    })
    .then(function (response) {
      set_show_map(true)

      if (response.data.code == "200") {
        // attendance_array

        setattendance_state(response.data.data[0].attendance_state)
        setattendance_last_check_in(  AttendFormat(response.data.data[0].last_check_in)   )
        setattendance_last_check_out(  AttendFormat( response.data.data[0].last_check_out )) 

        setattendance_distance(response.data.distance);
        setattendance_latoffice(response.data.latoffice);
        setattendance_lonoffice(response.data.lonoffice);

        const temp = [];
        temp.push(
            {
              distance: response.data.distance,
              latoffice: response.data.latoffice,
              lonoffice: response.data.lonoffice,
              kantor: 1,
            },
            {
              distance: response.data.distance2,
              latoffice: response.data.latoffice2,
              lonoffice: response.data.lonoffice2,
              kantor: 2,
            },
            {
              distance: response.data.distance3,
              latoffice: response.data.latoffice3,
              lonoffice: response.data.lonoffice3,
              kantor: 3,
            },
            {
              distance: response.data.distance4,
              latoffice: response.data.latoffice4,
              lonoffice: response.data.lonoffice4,
              kantor: 4,
            },
            {
              distance: response.data.distance5,
              latoffice: response.data.latoffice5,
              lonoffice: response.data.lonoffice5,
              kantor: 5,
            },
        );

        setattendance_array(temp);
      } else if ( response.data.code == '300' ) {
        nav("update-app", {
          login: login_xx,
          password: password_xx
        })
      } else {
        alert(response.data.message)
      }
    })
    .catch(function (error) {
      alert("Error attn check")
      set_show_map(true)
      console.log(error);
    });
  
  
  };

  const cekLocationAgain = async ( type_check = null ) => {
    set_show_map(false);

    navigator.geolocation.getCurrentPosition((position) => {
      set_user_position([
        position.coords.latitude,
        position.coords.longitude,
      ])
      set_cur_lat(position.coords.latitude)
      set_cur_lon(position.coords.longitude)

      handleGetAllContactRadius({
          lat: position.coords.latitude,
          lon: position.coords.longitude
      })

      setLocation(position);

      let text = 'Waiting..';
      setLocationDisplayName(text);
      if (errorMsg) {
          alert(errorMsg)
          text = errorMsg;
          return false;
      } else if (position) {
          text = (position);
          setLatitude(position.coords.latitude)
          setLongitude(position.coords.longitude)
          var mocked = text.mocked
          setCheckedMocked(mocked);
          if (mocked) {
            alert("fake gps detected #1");
            return false;
          }
      }
      setTypeCheck(type_check)
      if (type_check == 'checkin' || type_check == 'checkOut') {
        officeRadius(position.coords.latitude,position.coords.longitude, type_check);
      } else {
        getOpenMapV2(position.coords.latitude,position.coords.longitude, type_check);
      }

    }, (error) => {
      console.log(error);
        setErrorMsg('Permission to access location was denied');
        alert('Permission to access location was denied')
        return false;
    });

    set_show_map(true);
   
  }

  const getOpenMapV2 = (lati,longi, type_check) => {
    console.log('check map getOpenMapV2')
    if (type_check == 'checkin' || type_check == 'checkOut') {
      console.log('attendance_array', attendance_array)

      attendance_array.forEach(element => {
        if ( element.distance != '-' ) {
          var raddd = radiusattend(type_check, element.latoffice, element.lonoffice, element.distance);
          console.log('console.log radius', raddd )
        }
      });
    }
  }

  const officeRadius = (lati,longi, type_check) => {
    console.log("check mocked 2")
    console.log(mocked);
    console.log("search location officeRadius")
    setCheckedMocked(mocked);
    if (mocked) {
      alert("gps f detected #2");
      return false;
    }

    if (type_check == 'checkin' || type_check == 'checkOut') {
      attendance_array.forEach(element => {
        if ( element.distance != '-' ) {
          var raddd = radiusattend(type_check, element.latoffice, element.lonoffice, element.distance);
          console.log('console.log radius', raddd )
        }
      });
    }
  };

  const radiusattend = (type_check,latoff,lonoff,distoff) => {
    // alert(lat);
    // alert(lon);
    // return false;
    set_show_map(false) 
    console.log('check radius')
        
    axios({
      method: 'post',
      url: MAPI+'/radiusattend',
      headers: {
        "Content-Type": "application/json", 
        // "Content-Type": "application/x-www-form-urlencoded",
      },
      data: {
        latoffice: latoff,
        lonoffice: lonoff,
        curlat: cur_lat,
        curlon: cur_lon,
        radius: distoff
      }
    })
    .then(function (response) {
      set_show_map(true)
      if (response.data.code == "200") {
        if (response.data.check === true ) {
          if (type_check == 'checkin') {
            checkIn()
            return true;
          }
  
          if (type_check == 'checkOut') {
            checkOut()
            return true;
          }

        } else {
          // alert("Out off service attendance")
          return false;
        }
      }

    })
    .catch(function (error) {
      alert("Error area attendance")
      set_show_map(true)
      console.log(error);
    });
    set_show_map(false)
  }

  const checkIn = () => {

    nav('/absen-camera', {
      state: {
        login: login,
        password: password,
        locationDisplayName: locationDisplayName,
        lat: cur_lat,
        lon: cur_lon,
        mocked: mocked,
        type_check: 'checkin'
      }
    })
  }

  const checkOut = () => {

    nav('/absen-camera', {
      state: {
        login: login,
        password: password,
        locationDisplayName: locationDisplayName,
        lat: cur_lat,
        lon: cur_lon,
        mocked: mocked,
        type_check: 'checkout'
      }
    })
  }

  if ( !show_map ) {

    // if ( !permision_location ) {
    //   return(
    //     <>
    //       <LoadingScreen>
    //           <button className='btn btn-primary' onClick={handlePermission}>'User denied app access location'</button>
    //       </LoadingScreen>
    //     </>
    //   )
    // }

    return(
      <>
          <LoadingScreen />
      </>
    )
  } 

  return (
    <>
        {/* <Camera /> */}
        {/* <CameraSenyum /> */}
        {/* <CameraFace /> */}
        <TopBar havingBack headerTitle={'Absen'} />
        <OpenMapContent user_position={user_position} height_map={'60vh'} />
        <div>
          <div className='text-center fw-bold'>Current Location</div>
          <p className='text-center'>{locationDisplayName}</p>
        </div>
        <div>
          <div className='text-center fw-bold'>Last Attendance</div>
                
          <p className='text-center'>{(attendance_state == 'checked_in' ? 'CHECK IN '+ attendance_last_check_in : 'CHECK OUT '+attendance_last_check_out)}</p>
        </div>
       <div className='d-flex justify-content-evenly'>
          <AppButton title={'Check In'} width={150} onClick={handleCheckin} />
          <AppButton title={'Check Out'} width={150} onClick={handleCheckout} />
       </div>
    </>
  )
}
