import React, { useContext, useEffect, useState } from 'react'
import { AppButton } from '../components/AppButton';
import { Footer } from '../components/Footer';
import { apiLogin } from '../api/LoginApi';
import  secureLocalStorage  from  "react-secure-storage";
import { AuthContext } from '../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';

export const Login = () => {
    const authContext = useContext(AuthContext);
    const nav = useNavigate();

    const [show_password, set_show_password] = useState(true);
    const [loading_screen, set_loading_screen] = useState(false);

    const [email, set_email] = useState('');
    const [password, set_password] = useState('');


    useEffect( () => {
        navigator.geolocation.getCurrentPosition(function(position) {
            console.log("Latitude is :", position.coords.latitude);
            console.log("Longitude is :", position.coords.longitude);
          });

    }, [])

    useEffect(() => {
        const is_login = authContext.isAuth();
        console.log('isAuth', is_login)
        if ( is_login ) {
            window.location.href="/beranda";
        }
 
	}, []);

    const handleLogin = async () => {
        if ( !email ) {
            alert("Email tidak boleh kosong");
            return false;
        }

        if ( !password ) {
            alert("Password tidak boleh kosong");
            return false;
        }

        try {
            set_loading_screen(true);
            const res = await apiLogin({
                login: email,
                password: password
            });
            set_loading_screen(false);
    
            console.log('res', res.data.code )
            if ( res.data.code == '200') {
                
                secureLocalStorage.setItem("employee_photo", res.data.authtodo[0].image_128);
                secureLocalStorage.setItem("name", res.data.authtodo[0].name);
                secureLocalStorage.setItem("job_title", res.data.authtodo[0].job_title);
                secureLocalStorage.setItem("login", res.data.authtodo[0].login);
                secureLocalStorage.setItem("password", password);
                secureLocalStorage.setItem("raven", res.data.authtodo[0].login);
                secureLocalStorage.setItem("mobile_phone", res.data.authtodo[0].mobile_phone);
                secureLocalStorage.setItem("x_firebase_token", res.data.authtodo[0].x_firebase_token);
                secureLocalStorage.setItem("employee_id", res.data.authtodo[0].employee_id[0]);
                secureLocalStorage.setItem("employee_name", res.data.authtodo[0].employee_id[1]);
                secureLocalStorage.setItem("company_id", res.data.authtodo[0].company_id[0]);
                secureLocalStorage.setItem("company_name", res.data.authtodo[0].company_id[1]);

                authContext.logIn();
                nav('beranda')
            }
            
        } catch (error) {
            if ( error.response.data.code == '400' ) {
                alert(error.response.data.message);
            }
        }
        
    }

  return (
    <>
        <div
            style={{
                height: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                alignContent: 'center',
            }}
        >
            <div className='d-block'>
                <div
                    className='mt-3'
                >
                    <label>Email</label>
                    <input type='email' value={email} onChange={ (e) => set_email(e.target.value)} className='form-control w-100' />
                </div>
                <div
                    className='mt-3'
                >
                    <label>Password</label>
                    <input type={show_password?'password':'text'} value={password} onChange={ (e) => set_password(e.target.value)} className='form-control w-100' />
                </div>

                <div
                    className='mt-3'
                >
                    <div className="form-check">
                    <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" onClick={() => set_show_password(!show_password)} />
                    <label className="form-check-label" htmlFor="defaultCheck1">
                        Show Password
                    </label>
                    </div>
                </div>

                <div
                    className='mt-3'
                >
                    <AppButton title={loading_screen?'Loading':'Login'} disabledx={loading_screen} onClick={ handleLogin } width={'100%'} />
                </div>
            </div>
        </div>
        <Footer />
    </>
  )
}
