import { createContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import secureLocalStorage from 'react-secure-storage';

const AuthContext = createContext();


const AuthProvider = ({ children }) => {
    const [isLoggedIn, setIsLoggedIn] = useState(null);
  
    const isAuth = () => {
        var raven = secureLocalStorage.getItem('raven');
        var login = secureLocalStorage.getItem('login');

        if ( raven || login ) {
            if ( raven == login) {
                return true
            } else {
                return false;
            }
        } else {
            return false;
        }
    };
    const logIn = () => {
        setIsLoggedIn(true);
    };
  
    const logOut = () => {
        secureLocalStorage.clear();
        window.location.href = '/';
      setIsLoggedIn(false);
    };
  
    return (
      <AuthContext.Provider value={{ isLoggedIn, logIn, logOut, isAuth }}>
        {children}
      </AuthContext.Provider>
    );
  };
  
  export { AuthContext, AuthProvider };