import React, { useEffect, useState } from 'react'
import TopBar from '../layouts/TopBar'
import BottomBar from '../layouts/BottomBar'
import secureLocalStorage from 'react-secure-storage'
import { apiMyEmployee } from '../api/ProfileApi'

const MyItem = (props) => {
  return (
    <div className='mt-3'>
        <div className='' style={{ fontSize: 12,}}>{props.title}</div>
        <div className='fw-bold' style={{ fontSize: 14,}}>{props.text}</div>
    </div>
  )
}

export const DetailProfile = () => {
  const [employee_photo, set_employee_photo] = useState( secureLocalStorage.getItem('employee_photo'));

  const [IdentificationId, setIdentificationId] = useState([]);
  const [Birthday, setBirthday] = useState([]);
  const [MobilePhone, setMobilePhone] = useState([]);
  const [WorkMobile, setWorkMobile] = useState([]);
  const [WorkPhone, setWorkPhone] = useState([]);
  const [WorkEmail, setWorkEmail] = useState([]);
  const [WorkAddress, setWorkAddress] = useState([]);
  const [WorkLocation, setWorkLocation] = useState([]);
  const [Department, setDepartment] = useState([]);
  const [JobTitle, setJobTitle] = useState([]);
  const [Address, setAddress] = useState([]);
  const [Nationality, setNationality] = useState([]);
  const [Gender, setGender] = useState([]);
  const [Dob, setDob] = useState([]);
  const [PlaceOfBirth, setPlaceOfBirth] = useState([]);
  const [CountryOfBirth, setCountryOfBirth] = useState([]);
  const [IdentificationNo, setIdentificationNo] = useState([]);
  const [Marital, setMarital] = useState([]);
  const [NumberOfChildren, setNumberOfChildren] = useState([]);
  const [EmergencyContact, setEmergencyContact] = useState([]);
  const [EmergencyPhone, setEmergencyPhone] = useState([]);
  const [Certificate, setCertificate] = useState([]);
  const [CertificateLevel, setCertificateLevel] = useState([]);
  const [Other, setOther] = useState([]);
  const [FieldOfStudy, setFieldOfStudy]     = useState([]);
  const [School, setSchool]     = useState([]);
  // IMAGES
  const [image_256, setimage_256] = useState([]);

  useEffect( () => {
    handleApiMyEmployee();
  }, [])

  const handleApiMyEmployee = async () => {
    const res = await apiMyEmployee();

    if ( res.data.code == '200') {
        setMobilePhone((res.data.data[0].mobile_phone?res.data.data[0].mobile_phone:''))
        setWorkPhone(res.data.data[0].work_phone)
        setWorkEmail(res.data.data[0].work_email)
        setWorkLocation(res.data.data[0].work_location.toUpperCase())
        setDepartment((res.data.data[0].department_id?res.data.data[0].department_id[1].split("/").pop(): ''))
        setJobTitle(res.data.data[0].job_title.toUpperCase())
        setGender( (res.data.data[0].gender? res.data.data[0].gender.toUpperCase() : ''))
        setBirthday(res.data.data[0].birthday)
        setMarital( (res.data.data[0].marital? res.data.data[0].marital.toUpperCase() : '') )
        setPlaceOfBirth(( res.data.data[0].place_of_birth? res.data.data[0].place_of_birth.toUpperCase() : '') )
        setEmergencyContact((res.data.data[0].emergency_contact? res.data.data[0].emergency_contact.toUpperCase() : '') )
        setEmergencyPhone(res.data.data[0].emergency_phone)
        setCountryOfBirth(res.data.data[0].country_of_birth)
        setCertificate(res.data.data[0].certificate)
        setFieldOfStudy(( res.data.data[0].study_field? res.data.data[0].study_field.toUpperCase() : '') )
        setSchool((res.data.data[0].study_school? res.data.data[0].study_school.toUpperCase() : '') )
        setimage_256((res.data.data[0].image_256?res.data.data[0].image_256:''))
    } else {
        alert(res.data.message)
    }
  }
  return (
    <div className='app-scroll'>
      <TopBar headerTitle={'Detail Profile'} havingBack={true} />
        <div className='container'>
          <div className='text-center mt-4 bg-primary text-white py-3 rounded'>
            <img src={`data:image/jpeg;base64,${employee_photo}`} style={{ height: 100                                                                                                                                                                  , borderRadius: 50,}} />
            <div className='fw-bold' style={{ fontSize: 16}}>{ secureLocalStorage.getItem('employee_name')  }</div>
            <div className='' style={{ fontSize: 12}}>{ secureLocalStorage.getItem('login')  }</div>

            <div>
                <div className='mt-3' style={{ fontSize: 12}}>Department</div>
                <div className='fw-bold' style={{ fontSize: 14}} >{ (Department?Department:'-')  }</div>
            </div>

            <div>
                <div className='mt-3' style={{ fontSize: 12}}>Job</div>
                <div className='fw-bold' style={{ fontSize: 14}} >{ secureLocalStorage.getItem('job_title')  }</div>
            </div>
          </div>
          <MyItem title="Mobile Phone" text={MobilePhone} />
          <MyItem title="Work Email" text={WorkEmail} />
          <MyItem title="Work Location" text={WorkLocation} />
          <MyItem title="Gender" text={Gender?Gender:'-'} />
          <MyItem title="Birthday" text={Birthday?Birthday:'-'} />
          <MyItem title="Place Of Birth" text={PlaceOfBirth?PlaceOfBirth:'-'} />
          <MyItem title="Country Of Birth" text={CountryOfBirth?CountryOfBirth:'-'} />
          <MyItem title="Study Field" text={FieldOfStudy?FieldOfStudy:'-'} />
          <MyItem title="Study School" text={School?School:'-'} />
          <MyItem title="Emergency Contact" text={EmergencyContact?EmergencyContact:'-'} />
          <MyItem title="Emergency Phone" text={EmergencyPhone?EmergencyPhone:'-'} />
        </div>
    </div>
  )
}
