import React, { useState, useRef, useEffect } from 'react';
import { useDimension } from '../hooks/useDimension';
import TopBar from '../layouts/TopBar';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppButton } from './AppButton';
import { attendanceCheckin, attendanceCheckout } from '../api/AttendanceApi';
import { LoadingScreen } from '../pages/LoadingScreen';

const CameraAbsen = () => {
    const [loading_screen, set_loading_screen]  = useState(false);
    const nav = useNavigate();
    const {width} = useDimension();
    const [imageSrc, setImageSrc] = useState(null);
    const videoRef = useRef();
    const [status_camera, set_status_camera] = useState('close');
    const {state}  = useLocation();

  useEffect( () => {
    // startCamera();
    console.log('state', state)
  }, [])

  const captureImage = () => {

    if  ( status_camera != 'play') {
      alert("Please start camera first");

      return false;
    }
    const canvas = document.createElement('canvas');
    // canvas.width = videoRef.current.videoWidth;
    // canvas.height = videoRef.current.videoHeight;
    canvas.width = width;
    canvas.height = videoRef.current.videoHeight * (width / videoRef.current.videoWidth);
    canvas.getContext('2d').drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);
    const imageSrc = canvas.toDataURL('image/jpeg');
    setImageSrc(imageSrc);
  };

  const startCamera = async () => {
    try {
      set_status_camera('play');
      setImageSrc(null)
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      videoRef.current.srcObject = stream;
      videoRef.current.play();
    } catch (error) {
      console.log('Error accessing camera:', error);
    }
  };

  const closeCamera = async () => {
      set_status_camera('close');
      window.location.reload();
  };

  const __savePhoto = async () => {
    console.log('imageSrc', imageSrc);
    set_loading_screen(true);
    var res = 'DOR';
    if ( state.type_check == 'checkin' ) {
        res = await attendanceCheckin( state.login, state.password, state.locationDisplayName, state.lat, state.lon, false, imageSrc.replace('data:image/jpeg;base64,','') );
    } else if (state.type_check == 'checkout')  {
        res =  await attendanceCheckout( state.login, state.password, state.locationDisplayName, state.lat, state.lon, false,  imageSrc.replace('data:image/jpeg;base64,','') );
    }
    set_loading_screen(false);


    if ( res.code == '200' ) {
        // DO
        // navigation.navigate("Attendance", {
        //     login: login,
        //     password: password,
        // })

        // nav("attendance")
        window.location.href = '/beranda'
    } else if ( res.code == '300' ) {
      alert(res.message)
    } else {
      if (res.response.data.code == '300') {
        alert(res.response.data.message)
      } else {
        alert(res.message);
      }
    }
    console.log('res save photo', res);
  }

  if ( loading_screen ) {
    return (
      <LoadingScreen />
    )
  }

  return (
    <div className='' style={{ width: width, height: '100vh' }}>
        <TopBar havingBack={true} headerTitle={'Absen Camera'}/>
      {/* <video ref={videoRef}></video> */}
      {
        imageSrc?
        <img src={imageSrc} alt="captured" />
        :
        <video ref={videoRef} width={width}></video>
      }

    

      <div>
      </div>
      <div className='sticky-bottom bottom-bar '>
          <div className='d-flex justify-content-center mb-3 align-items-center text-center'>
            {
              imageSrc?
              <>
                {state.type_check == 'checkin'?
                
                  <AppButton 
                    title={'Check In'}
                    onClick={__savePhoto}
                  />
                  :
                  <AppButton 
                    title={'Check Out'}
                    onClick={__savePhoto}
                  />
                }
              </>
              :
              <></>
            }
          </div>

          <div className='text-center d-flex justify-content-between' >
            {
              status_camera=='play'?
              <button className='btn btn-secondary w-50' onClick={closeCamera}><i className='fa-solid fa-close'></i></button>
              :
              <button className='btn btn-secondary w-50' onClick={startCamera}><i className='fa-solid fa-play'></i></button>
            }
            {
              imageSrc?
              <button className='btn btn-primary w-50' onClick={closeCamera}>Re-Take</button>
              :
              <button className='btn btn-primary w-50' onClick={captureImage}><i className='fa-solid fa-camera'></i></button>
            }
          </div>
      </div>
      {/* {imageSrc && <img src={imageSrc} alt="captured" />} */}
    </div>
  );
};

export default CameraAbsen;
