import React, { useState } from 'react'
import { apiOvertimeAdd } from '../api/DashboardOvertimeApi'
import TopBar from '../layouts/TopBar';
import { AppButton } from '../components/AppButton';

const DashboardOvertimeCreate = () => {
    const [date_from, set_date_from] = useState('');
    const [date_to, set_date_to] = useState('');
    const [desc, set_desc] = useState('');
    const handleSave = async () => {

        const res = await apiOvertimeAdd({
            date_from: date_from,
            date_to: date_to,
            desc: desc,
        })

        if ( res.data.code == '200') {
             set_date_from('')        
             set_date_to('')        
             set_desc('') 
             alert("Berhasil membuat overtime")       
        }

        console.log('resresresresresresresresresres', res);
    }

  return (
    <>
        <TopBar headerTitle={'Dashboard Overtime Create'} havingBack={true}/>
        <div className='container'>
            <div className='row mt-3'>
                <div className='col-6'>
                    <label>Date From</label>
                    <input value={date_from} type='datetime-local' className='form-control' placeholder='date_from' onChange={(e) => set_date_from(e.target.value)} />
                </div>
                <div className='col-6'>
                    <label>Date To</label>
                    <input value={date_to} type='datetime-local' className='form-control' placeholder='date to' onChange={(e) => set_date_to(e.target.value)} />
                </div>
            </div>

            <div className='row mt-3'>
                <div className='col-12'>
                    <label>Description</label>
                    <textarea value={desc} className='form-control' onChange={(e) => set_desc(e.target.value)}></textarea>
                </div>
            </div>

            <div className='row mt-3'>
                <div className='col-12'>
                    <AppButton title={'Save'} onClick={handleSave} />
                </div>
            </div>
        </div>
    </>
  )
}

export default DashboardOvertimeCreate