import React, { useContext } from 'react'
import { AuthContext } from '../contexts/AuthContext';
import { Link } from 'react-router-dom';

export const NotFound = () => {
    const authContext = useContext(AuthContext);
  return (
    <div
        style={{
            display: 'flex',
            justifyContent: 'center',
            height: '100vh',
            alignItems: 'center'
        }}
    >
        <div className='d-block text-center'>
            <h5>
                404 | Not Found
            </h5>
            <h5>
                <Link to={authContext.isAuth()?'/beranda':'/'}>Back</Link>
            </h5>
        </div>
    </div>
  )
}
