import React from 'react'
import TopBar from '../layouts/TopBar'

const Desk = () => {
  return (
    <>
        <TopBar headerTitle={'Desk'} />
        <div className='container'>Desk</div>
    </>
  )
}

export default Desk