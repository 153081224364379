import React from 'react'
import Camera from '../components/Camera'
import CameraAbsen from '../components/CameraAbsen'
import TopBar from '../layouts/TopBar'

const AbsenCamera = () => {
  return (
    <div>
        <CameraAbsen />
    </div>
  )
}

export default AbsenCamera