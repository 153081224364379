import React, { useEffect, useState } from 'react'
import TopBar from '../layouts/TopBar'
import BottomBar from '../layouts/BottomBar'
import secureLocalStorage from 'react-secure-storage'
import { useNavigate } from 'react-router-dom'
import { apiMyEmployee } from '../api/ProfileApi'

const MyItem = (props) => {
  return (
    <div className='d-flex justify-content-between mb-1 btn' onClick={props.onClick}>
      <div className='d-flex'>
        <div><i className={props.icon}></i></div>
        <div className='ms-3'>{props.name}</div>
      </div>
      <div><i className='fa-solid fa-angle-right'></i></div>
    </div>
  )
}

export const Profile = () => {
  const nav = useNavigate();

  const [employee_photo, set_employee_photo] = useState( secureLocalStorage.getItem('employee_photo'));


  return (
    <div>
      <TopBar headerTitle={'Profile'} />
        <div className='container'>
          <div className='text-center mt-4'>
            <img src={`data:image/jpeg;base64,${employee_photo}`} style={{ height: 55, borderRadius: 50,}} />
            <div className='fw-bold' style={{ fontSize: 16}}>{ secureLocalStorage.getItem('employee_name')  }</div>
            <div className='' style={{ fontSize: 14}} >{ secureLocalStorage.getItem('company_name')  }</div>
            <div className='' style={{ fontSize: 12}}>{ secureLocalStorage.getItem('login')  }</div>
          </div>
          <div className='mt-3'>
            <p className='fw-bold' style={{ fontSize: 14,}}>Setting Profile</p>
            <MyItem name="Detail Profile" icon={'fa fa-user'} onClick={ () => nav('/detail-profile')}/>
            <MyItem name="Employee Card" icon={'fa fa-address-card'}/>
            <MyItem name="Change Password" icon={'fa fa-user-lock'}/>
          </div>
        </div>
      <BottomBar />
    </div>
  )
}
