import React from 'react';
import logo from './logo.svg';
import './App.css';
import { MainRoute } from './routes/MainRoute';
import { MobileLayout } from './layouts/MobileLayout';
import { AuthProvider } from './contexts/AuthContext';

function App() {
  return (
    <>
      <AuthProvider>
        <MobileLayout>
          <MainRoute />
        </MobileLayout>
      </AuthProvider>
    </>
  );
}

export default App;
