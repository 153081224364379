import React, { useState } from 'react'

import { MAPI, primaryColor } from '../../helper/Constant';

export const CardPayroll = (props) => {
    const regex = /(<([^>]+)>)/ig;

  const [containerColor, setContainerColor] = useState('dark');

  
const styles = {
    container: {
        flexBasis: 75,
        borderWidth: 2,
        borderRadius: 20,
        marginLeft: 20,
        marginRight: 20,
        marginTop: 10,
        marginBottom: 10,
        padding: 10,
    },
    titleView: {
      flex: 1,
      marginBottom: 10,
    },
    titleText: {
      fontSize: 18,
      color: primaryColor
    },
    ContentView: {
      flex: 1,
      marginBottom: 10,
    },
    ContentText: {
      fontSize: 14,
    },
    newsFooterView: {
      flex: 0,
    },
    newsFooterText: {
      fontSize: 12,
    }
  }


  return (
    <div onClick={props.onClick}>
      <div className='card card-danger m-3 p-2'  >
        <div style={styles.titleView}>
          <div style={styles.titleText}>{props.title}</div>
        </div>
        <div style={styles.ContentView}>
          <div style={styles.ContentText}>{props.name}</div>
        </div>
      </div>
    </div>
  )
}




