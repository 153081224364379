import React, { useContext } from 'react';
import useLastLocation from '../hooks/useLastLocation';
import { AuthContext } from '../contexts/AuthContext';
import { Link, useNavigate } from 'react-router-dom';
import { useDimension } from '../hooks/useDimension';

function TopBar({headerLeft, headerTitle, headerRight, havingBack}) {
    const lastLocation = useLastLocation();
    const authContext = useContext(AuthContext)
    const { width } = useDimension();

    const nav = useNavigate()
    return (
        <div className='d-flex justify-content-between bg-lig p-3 bg-light shadow sticky-top'>
            {
                havingBack?
                <a className={`navbar-brand text-center btn col-1`} onClick={() => nav(-1)}><small> <i className="fa-solid fa-arrow-left"></i> </small></a>
                :
                <></>
            }
            <a className={`navbar-brand text-center ${lastLocation=='/profilex'?'text-primary':''}`} href="#"><small className='fw-bold h5'> <span >{headerTitle}</span></small></a>
            <a className={`navbar-brand text-center ${lastLocation=='/profilex'?'text-primary':''}`} href="#"><small> <span >&nbsp;</span></small></a>
            <a className={`navbar-brand text-center ${lastLocation=='/profilex'?'text-primary':''}`} href="#"><small> <span >&nbsp;</span></small></a>
            <a className={`navbar-brand text-center ${lastLocation=='/profilex'?'text-primary':''}`} href="#"><small> <span >
            {headerRight?headerRight:''}    
            </span></small></a>
        </div>
  );
}

export default TopBar;
