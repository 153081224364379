import React, { useEffect, useState } from 'react'
import TopBar from '../layouts/TopBar'
import BottomBar from '../layouts/BottomBar'
import { apiGetPayrollList } from '../api/PayrollApi';
import {CardPayroll} from '../components/payroll/CardPayroll';
import { useNavigate } from 'react-router-dom';
import { LoadingScreen } from './LoadingScreen';

const Payroll = () => {
  const [loading_screen, set_loading_screen] = useState(false);
  const nav = useNavigate();
  const [ payroll_data, set_payroll_data ] = useState([]);

  useEffect( () => {
    getPayrollList();
  }, [])

  const getPayrollList =  async (login_x,password_x) => {
    set_loading_screen(true)
    const res = await apiGetPayrollList()
    set_loading_screen(false);

    console.log('pay pay', res.data.data);
      set_payroll_data(res.data.data)
  }

  
  if ( loading_screen ) {
    return (
      <LoadingScreen />
    )
  }


  return (
    <div>
      <TopBar headerTitle={'Payroll'} />
      <div className='app-scroll'>
      {
        payroll_data.map((item,index) => {
          return (
            <div
             key={index}
            >
                <CardPayroll
                  onPress={ 
                    () => nav('payroll/detail', {
                      state : {
                        slip_id: item.id,
                        name: item.name,
                      }
                    })
                  }
                  id={item.id}
                  title={item.company_id[1]}
                  name={item.name}
                />
            </div>
          )
        })
      }
      </div>
      <BottomBar />
    </div>
  )
}

export default Payroll