import React, { useState } from 'react'

export const AppButton = ({title,onClick, width, disabledx}) => {
  const [disabled, set_disabled] = useState(disabledx)
  return (
    <div
        
    >
        <button type='button' disabled={disabled} className='btn btn-primary' onClick={onClick}
            style={{
                width: width?width:'auto'
            }}
        >{title}</button>
    </div>
  )
}
