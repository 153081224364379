import React, { useContext } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { Login } from '../pages/Login'
import { PublicRoutes } from './PublicRoutes'
import { PrivateRoute } from './PrivateRoute'
import { AuthContext } from '../contexts/AuthContext'

export const MainRoute = () => {
  const authContext = useContext(AuthContext);
  return (
    <>
        <BrowserRouter >
            <Routes>
              {
                authContext.isAuth()?
                <>
                  <Route path='/*' element={ <PrivateRoute /> } />
                </>
                :
                <>
                  <Route path='/*' element={ <PublicRoutes /> } />
                </>
              }
            </Routes>
        </BrowserRouter>
    </>
  )
}
