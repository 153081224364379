import axios from "axios";
import { MAPI } from "../helper/Constant";
import secureLocalStorage from "react-secure-storage";

export const apiOvertimeList = async (props) => {
    try {

        const login = await secureLocalStorage.getItem('login');
        const password = await secureLocalStorage.getItem('password');

        const res = await axios({
            method: 'post',
            url: MAPI+'/overtimelist',
            headers: {
              "Content-Type": "application/json", 

            },
            data: {
                email: login,
                password: password,
            }
        })

        return res;
        
    } catch (error) {
        return {
            code: 666,
            message: error,
            data: null
        }
    }
}


export const apiOvertimeAdd = async (props) => {
    try {

        const login = await secureLocalStorage.getItem('login');
        const password = await secureLocalStorage.getItem('password');

        const res = await axios({
            method: 'post',
            url: MAPI+'/overadd',
            headers: {
              "Content-Type": "application/json", 
            },
            data: {
              email: login,
              password: password,
              date_from: props.date_from.replace('T',' '),
              date_to: props.date_to.replace('T',' '),
              desc: props.desc,
            }
        });

        return res;
        
    } catch (error) {
        return {
            code: 666,
            message: error,
            data: null
        }
    }
}

