import React from 'react'
import { Footer } from '../components/Footer'

export const MobileLayout = (props) => {
  return (
    <div
        style={{
            display: 'flex',
            justifyContent: 'center',
        }}
    >
        <div
            className='shadow'
             style={{
                width: 426,
                minHeight: '100vh',
                // border: '1px solid red'

            }}
        >
            {props.children}
        </div>
    </div>
  )
}
