import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { Login } from '../pages/Login'
import { Beranda } from '../pages/Beranda'
import { NotFound } from '../pages/NotFound'

export const PublicRoutes = () => {
  return (
    <>
        <Routes>
            <Route path='*' element={ <NotFound />} />
            <Route path='/' element={ <Login />} />
        </Routes>
    </>
  )
}
