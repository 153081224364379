import React, { useEffect, useState } from 'react'
import TopBar from '../layouts/TopBar'
import { LoadingScreen } from './LoadingScreen';
import { apiMyAttendance } from '../api/HomeApi';
import { primaryColor } from '../helper/Constant';

const Item = ({ item, onPress, backgroundColor, textColor, textColorLate }) => (
    <div className={`card shadow  p-3 m-3 ${item.late_check_in>0?'border-danger':'border-primary'}`}  >
      <div>{item.display_name}</div>
      <div className='row justify-content-between align-item-center' >
        <div className='col-5 justify-content-center align-conten-center'>
          {
            item.x_ci_image?
              <img src={`data:image/jpeg;base64,${item.x_ci_image}`} style={styles.imageS} />
            :
            <div 
                className='card'
              style={styles.noImage}
            >
              <div style={{ fontSize: 12}}> IMAGE IN </div>
            </div>
          }
        </div>
        <div className='col-5 justify-content-center align-conten-center'>
          {
            item.x_co_image?
              <img src={`data:image/jpeg;base64,${item.x_co_image}`} style={styles.imageS} />
            :
            <div 
                className='card'
                style={ styles.noImage}
            >
              <div style={{ fontSize: 12}}>IMAGE OUT</div>
            </div>
          }
        </div>
      </div>
      <div >Late:  {item.late_check_in}</div>
    </div>
  );
  

const AttendanceReport = () => {
    const [loading_screen, set_loading_screen] = useState(false);
    const [ selectedId, setSelectedId ] = useState(null);
    const [ myattendance_data, setmyattendance_data ] = useState([]);

    useEffect( () => {
        getApiMyAttendance();
    }, [])

    const getApiMyAttendance = async () => {
        set_loading_screen(true);
        const response = await apiMyAttendance();
        set_loading_screen(false);
        if (response.data.code === "200") {
          console.log('response.data.myattendance', response.data.myattendance)
            setmyattendance_data(response.data.myattendance)
        } else {
            alert(response.data.message)
        }
    }

    if ( loading_screen ) {
        return (
            <LoadingScreen>

            </LoadingScreen>
        )
    }
  return (
    <div>
        <TopBar havingBack={true} headerTitle={'Attendance Report'} />
        {
            myattendance_data.map((v,i) => {
                return (
                    <Item 
                        item={v}
                    />
                )
            })
        }
    </div>
  )
}

export default AttendanceReport

const styles = {
    container: {
        flex: 1,
        // paddingTop: StatusBar.currentHeight,
    },
    containerItem: {
        padding: 10,
        margin: 10,
        paddingTop: 10,
        borderWidth: 1,
        borderColor: primaryColor,
        borderRadius: 10,
        backgroundColor: "#fff"
    },
      scrollView: {
        // backgroundColor: 'pink',
        flex: 1,
        marginHorizontal: 0,
      },
      text: {
        fontSize: 42,
      },
    
      buttonsView: {
        flex: 1,
        alignItems: "center"
      },
    
      buttonView: {
        marginTop: 20,
        marginBottom: 20,
        width: '50%',
        backgroundColor: "#9370db",
        borderRadius: 30,
        overflow: "hidden",
      },

      textDisplayName: {
        marginBottom: 10,
      },

      imageS: {
        width: 88,
        height: 88,
        alignSelf: 'center',
        borderRadius: 7,
        marginTop: 8,
        marginBottom: 8,
      },

      noImage: {
        borderWidth: 1,
        borderColor: primaryColor,
        borderRadius: 10,
        backgroundColor: "#fff",
        width: 88,
        height: 88,
        alignSelf: 'center',
        marginTop: 8,
        marginBottom: 8,
        justifyContent: 'center',
        alignItems: 'center'
      }
}