import React, { useEffect, useState } from 'react'
import TopBar from '../layouts/TopBar'
import { AppButton } from '../components/AppButton'
import { apiOvertimeList } from '../api/DashboardOvertimeApi';
import { LoadingScreen } from './LoadingScreen';
import { primaryColor } from '../helper/Constant';
import { useNavigate } from 'react-router-dom';

const ItemComponent = ({item}) => {
    return (
        <div className='card' style={{ borderWidth: 1, borderColor: (item.state == 'approved'?primaryColor: ''), paddingTop: 10,paddingLeft: 10,paddingRight: 10, marginBottom: 20, borderRadius: 15, }}>
            <div className='row mb-3'>
                <div style={{ width: '31%'}}>
                    <div style={{ color:primaryColor}}>NAME</div>
                </div>
                <div style={{ width: '31%'}}>
                    <div>{item.name}</div>
                </div>
            </div>
            <div className='row mb-3'>
                <div style={{ width: '31%'}}>
                    <div style={{ color:primaryColor}}>STATE</div>
                </div>
                <div style={{ width: '31%'}}>
                    <div>{item.state.toUpperCase()}</div>
                </div>
            </div>
            <div className='row mb-3'>
                <div style={{ width: '31%'}}>
                    <div style={{ color:primaryColor}}>FROM</div>
                </div>
                <div style={{ width: '31%'}}>
                    <div>{item.date_from}</div>
                </div>
            </div>
            <div className='row mb-3'>
                <div style={{ width: '31%'}}>
                    <div style={{ color:primaryColor}}>TO</div>
                </div>
                <div style={{ width: '31%'}}>
                    <div>{item.date_to}</div>
                </div>
            </div>
        </div>
    )
}

export const DashboardOvertime = () => {
    const nav = useNavigate();
    const [loading_screen, set_loading_screen] = useState(false);
    const [overtimeListData, setovertimeListData] = useState([]);

    useEffect( () => {
        overtimelist();
    }, []);

    const overtimelist = async () => {
        set_loading_screen(true);
        const res = await apiOvertimeList();
        set_loading_screen(false);
        if ( res.data.code == '200') {
            setovertimeListData(res.data.Data)
        }
    };

    if ( loading_screen ) {
        return (
            <LoadingScreen />
        )
    }
  return (
    <div>
        <TopBar headerTitle={'Dashboard Overtime'} havingBack />
        <div className='container'>
            <div className='mt-3'>
                <AppButton 
                    title={'Create'}
                    width={'30%'}
                    onClick={() => nav('/dashboard-overtime-create')}
                />
            </div>
            <div className='mt-3' style={{
                overflow: 'scroll',
                overflowX: 'hidden',
                height: '80vh',
            }}>
                {
                    overtimeListData.map((v,i) => {
                        return (
                            <ItemComponent 
                                item={v}
                            />
                        )
                    })
                }

            </div>
        </div>
    </div>
  )
}
