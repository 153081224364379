import React from 'react'
import { MapContainer, TileLayer, Marker, Popup,   } from 'react-leaflet'
import { useMap } from 'react-leaflet/hooks'

// export const OpenMapContent = ({ location, zoomLevel }) => (

//     // <MapContainer center={[40.505, -100.09]} zoom={13}  >
//     //     <TileLayer
//     //         attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
//     //         url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
//     //     />
//     //     <Marker position={[40.505, -100.09]}>
//     //         <Popup>
//     //             I am a pop-up!
//     //         </Popup>
//     //     </Marker>
//     // </MapContainer>

//     const position = [51.505, -0.09];

//     return (
      

//     )

// )

// Mendapatkan alamat dari koordinat
function getAddressFromCoordinates(lat, lng) {
  const url = `https://nominatim.openstreetmap.org/reverse?lat=${lat}&lon=${lng}&format=jsonv2`;

  return fetch(url)
    .then(response => response.json())
    .then(data => {
      const address = data.address;
      const street = address.road || '';
      const city = address.city || address.town || '';
      const state = address.state || '';
      const country = address.country || '';
      const postcode = address.postcode || '';
      
      // Mengembalikan alamat dalam format teks
      return `${street}, ${city}, ${state} ${postcode}, ${country}`;
    })
    .catch(error => {
      console.log(error);
    });
}


const OpenMapContent = ({user_position, height_map}) => {
  const position = user_position
  // style={{ width: '400px'}}
  return (
    <div >
        <MapContainer center={position} zoom={20} scrollWheelZoom={false} style={{ height: height_map?height_map:''}}>
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Marker position={position}>
          <Popup>
            Aku disini
          </Popup>
        </Marker>
      </MapContainer>
    </div>
  )
}

export {OpenMapContent, getAddressFromCoordinates}