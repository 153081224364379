import React, { useEffect, useState } from 'react'
import TopBar from '../layouts/TopBar'
import { AppButton } from '../components/AppButton'
import { useNavigate } from 'react-router-dom'
import { apiLeaveList } from '../api/DasboardLeaveApi'
import { LoadingScreen } from './LoadingScreen'
import { primaryColor } from '../helper/Constant'

export const DashboardLeave = () => {
  const nav = useNavigate();
  const [loading_screen, set_loading_screen] = useState(false);

  const [leave_list_data, set_leave_list_data] =useState([]);

  useEffect( ( ) => {
    handleLeaveList();
  }, [])


  const handleLeaveList = async () => {
    set_loading_screen(true);
    const res = await apiLeaveList();
    set_loading_screen(false);
    if ( res.data.code == '200') {
        set_leave_list_data(res.data.Data)
    }
};

if ( loading_screen) {
  return (
    <LoadingScreen />
  )
}

  return (
    <div className='app-scroll '>
      <TopBar havingBack={true} headerTitle={'Dashboard Leave'} />
      <div className='container py-3'>
        <AppButton 
            title={'Create'}
            width={'30%'}
            onClick={() => nav('/dashboard-leave-create')}
        />

      <div className='mt-3'>
        {
          leave_list_data.map( (item,index) => {
            return (
              <div className='card' style={{ borderWidth: 1, borderColor: (item.state == 'confirm'?primaryColor: ''), paddingTop: 10,paddingLeft: 10,paddingRight: 10, marginBottom: 20, borderRadius: 15, }}>
                <div className='row' style={{ marginBottom: 20,  }}>
                    <div style={{ width: '31%'}}>
                        <span style={{ color:primaryColor}}>NAME</span>
                    </div>
                    <div style={{ width: '69%'}}>
                        <span>{item.name}</span>
                    </div>
                </div>
                <div className='row' style={{ marginBottom: 20, }}>
                    <div style={{ width: '31%'}}>
                        <span style={{ color:primaryColor}}>STATE</span>
                    </div>
                    <div style={{ width: '69%'}}>
                        <span>{item.state.toUpperCase()}</span>
                    </div>
                </div>
                <div className='row' style={{ marginBottom: 20, }}>
                    <div style={{ width: '31%'}}>
                        <span style={{ color:primaryColor}}>LEAVE FROM</span>
                    </div>
                    <div style={{ width: '69%'}}>
                        <span>{item.request_date_from}</span>
                    </div>
                </div>
                <div className='row' style={{ marginBottom: 20, }}>
                    <div style={{ width: '31%'}}>
                        <span style={{ color:primaryColor}}>LEAVE TO</span>
                    </div>
                    <div style={{ width: '69%'}}>
                        <span>{item.request_date_to}</span>
                    </div>
                </div>
            </div>
            )
          })
        }
      </div>
      </div>
    </div>
  )

}
