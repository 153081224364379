import React from 'react'
import TopBar from '../layouts/TopBar'
import BottomBar from '../layouts/BottomBar'
import { AppButton } from '../components/AppButton'
import { useNavigate } from 'react-router-dom'

export const Attendance = () => {
  const nav = useNavigate();

  return (
    <>
      <TopBar headerTitle={'Attendance'} />
        <div className='container d-flex justify-content-center' style={{ height: '92%'}}>
          <div className='d-block text-center'>
            <div className='my-5'></div>
            <AppButton width={200} title={'ABSEN'} onClick={() => nav('/absen')}/>
            <div className='my-5'></div>
            <AppButton width={200} title={'REPORT'} onClick={() => nav('/attendance-report')}/>
            <div className='my-5'></div>
            <AppButton width={200} title={'DASHBOARD LEAVE'} onClick={() => nav('/dashboard-leave')}/>
            <div className='my-5'></div>
            <AppButton width={200} title={'DASHBOARD OVERTIME'} onClick={() => nav('/dashboard-overtime')}/>
          </div>
        </div>
      <BottomBar />
    </>
  )
}
