import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { Login } from '../pages/Login'
import { Beranda } from '../pages/Beranda'
import { NotFound } from '../pages/NotFound'
import { More } from '../pages/More'
import { Attendance } from '../pages/Attendance'
import { Absen } from '../pages/Absen'
import AbsenCamera from '../pages/AbsenCamera'
import AttendanceReport from '../pages/AttendanceReport'
import { DashboardLeave } from '../pages/DashboardLeave'
import { DashboardOvertime } from '../pages/DashboardOvertime'
import DashboardOvertimeCreate from '../pages/DashboardOvertimeCreate'
import Desk from '../pages/Desk'
import { Profile } from '../pages/Profile'
import { DetailProfile } from '../pages/DetailProfile'
import Payroll from '../pages/Payroll'

export const PrivateRoute = () => {
  return (
    <>
        <Routes>
            <Route path='*' element={ <NotFound />} />
            <Route path='/' element={ <Login />} />
            <Route path='/beranda' element={ <Beranda />} />
            <Route path='/payroll' element={ <Payroll />} />
            <Route path='/profile' element={ <Profile />} />
            <Route path='/detail-profile' element={ <DetailProfile />} />
            <Route path='/attendance' element={ <Attendance />} />
            <Route path='/absen-camera' element={ <AbsenCamera />} />
            <Route path='/attendance-report' element={ <AttendanceReport />} />
            <Route path='/absen' element={ <Absen />} />
            <Route path='/dashboard-leave' element={ <DashboardLeave />} />
            <Route path='/dashboard-overtime' element={ <DashboardOvertime />} />
            <Route path='/dashboard-overtime-create' element={ <DashboardOvertimeCreate />} />
            <Route path='/more' element={ <More />} />
            <Route path='/desk' element={ <Desk />} />
        </Routes>
    </>
  )
}
