import React, { useContext } from 'react';
import useLastLocation from '../hooks/useLastLocation';
import { AuthContext } from '../contexts/AuthContext';
import { Link } from 'react-router-dom';
import { useDimension } from '../hooks/useDimension';

const ItemBottom = ({to_link, name,icon}) => {
    const lastLocation = useLastLocation();
    return (
    <Link class={`navbar-brand text-center ${lastLocation== to_link?'text-primary':''}`} to={to_link}><small> <i class={icon} style={{ fontSize: 20}}></i> <div style={{ fontSize: 10}}>{name}</div></small></Link>
  )
}

function BottomBar() {
    const lastLocation = useLastLocation();
    const authContext = useContext(AuthContext)
    const { width } = useDimension();
    return (
   <>
    <div className='sticky-bottom bottom-bar' style={{ width: width}}>
        <div className='navbar bg-light d-flex justify-content-between px-3'>
          <ItemBottom to_link={'/beranda'} name={'Beranda'} icon={'fa-solid fa-house'} />
          <ItemBottom to_link={'/attendance'} name={'Attendance'} icon={'fa-solid fa-check'} />
          <ItemBottom to_link={'/payroll'} name={'Payroll'} icon={'fa-solid fa-dollar'} />
          <ItemBottom to_link={'/profile'} name={'Profile'} icon={'fa-solid fa-user'} />
          <ItemBottom to_link={'/more'} name={'More'} icon={'fa-solid fa-bars'} />
        </div>
    </div>
  </>
  );
}

export default BottomBar;
