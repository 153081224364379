import React, { useContext } from 'react'
import TopBar from '../layouts/TopBar'
import BottomBar from '../layouts/BottomBar'
import { AuthContext } from '../contexts/AuthContext'
import { version_apk } from '../helper/Constant'
import { useNavigate } from 'react-router-dom'

export const More = () => {
    const authContext = useContext(AuthContext);
    const nav = useNavigate();
  return (
    <div>
        <TopBar 
            headerTitle={'More'}  
        />
        <div className='mt-0'>
            {/* <div className='w-100 text-start border-bottom btn p-2'>CRM</div> */}
            <div className='w-100 text-start border-bottom btn p-2' onClick={ () => nav('/desk')}>Desk</div>
            <div className='w-100 text-start border-bottom btn p-2'>Employee</div>
            <div className='w-100 text-start border-bottom btn p-2'>Discuss</div>
            <div className='w-100 text-start border-bottom btn p-2'>My Ticket List</div>
            <div className='w-100 text-start border-bottom btn p-2'>My Task</div>
            <div className='w-100 text-start border-bottom btn p-2' onClick={ () => authContext.logOut()}>Logout</div>
        </div>
        <div className='text-center mt-3'>V-{version_apk}</div>
        <BottomBar />
    </div>
  )
}
