import React from 'react'

export const LoadingScreen = (props) => {
  return (
    <div
        className='d-flex justify-content-center align-items-center'
        style={{
            height: '100vh'
        }}
    >
        <div className='d-block text-center'>
            <p>LOADING</p>
            {props.children}
        </div>
    </div>
  )
}
