import { date_formate_absen_hari, date_formate_absen_jam, date_human_readble } from "../helper/GlobalFunction";

export const AttendanceItem = (props) => {
    const success =  '#6FCF97';
    const warning = '#F2C94C';
    const danger = '#EB5757';
    const bg_ok = (parseInt(props.late)>0? danger : ( props.jam_masuk && props.jam_pulang  ?success: warning));
    return (
        <>
            <div
                className="shadow mb-3 mt-1 mx-3"
                style={
                    {
                        backgroundColor: '#F8F8F8',
                        borderWidth: 1,
                        borderRadius: 6,
                        borderColor: '#F8F8F8',
                        padding: 15,
                    }
                }
            >
                
                <div 
                    className="row justify-content-between"
                >

                    <div
                        className="text-center col-4"
                    
                    >
                        <span style={{ color: '#BDBDBD', fontSize: 16, fontWeight: 400}}>Masuk</span>
                        <div >
                            {
                                props.ci_image?
                                
                                <img src={`data:image/png;base64,${props.ci_image}`} style={styles.imageS} />

                                :
                                <div 
                                style={styles.noImage}
                                >
                                <span style={{ fontSize: 12}}> IMAGE IN </span>
                                </div>
                            }
                        </div>
                        <span style={{ color: bg_ok, fontSize: 18, fontWeight: 600}}>{props.jam_masuk?date_formate_absen_jam(props.jam_masuk):'-'}</span>
                    </div>

                    <div
                        className="text-center col-4"
                      
                    >
                        <span style={{ color: '#BDBDBD', fontSize: 16, fontWeight: 400}}>Keluar</span>
                        {
                            props.co_image?
                            <img src={`data:image/png;base64,${props.co_image}`} style={styles.imageS} />
                            :
                            <div 
                                style={styles.noImage}
                            >
                                <span style={{ fontSize: 12}}> IMAGE OUT </span>
                            </div>
                        }
                        <span style={{ color: bg_ok, fontSize: 18, fontWeight: 600}}>{(props.jam_pulang?date_formate_absen_jam(props.jam_pulang):'-')}</span>
                    </div>

                    {/* <div
                        style={
                            {
                                borderWidth: 1,
                                paddingVertical: 8,
                                paddingHorizontal: 12,
                                backgroundColor: bg_ok,
                                borderColor: bg_ok,
                                borderRadius: 6,
                                alignItems: 'center',
                                width: 64,
                            }
                        }
                    >
                        <span style={{ color: '#fff', fontSize: 16, fontWeight: 600}}>{new Date(props.id?props.id:'1997-02-07').getDate()} {date_formate_absen_hari(props.id?props.id:'1997-02-07')} </span>
                        <span style={{ color: '#fff', fontSize: 12, fontWeight: 600}}>{date_formate_absen_hari(props.id?props.id:'1997-02-07')}</span>
                    </div> */}

                </div>

                <div
                    className="p-2 text-center"
                    style={
                        {
                            borderWidth: 1,
                            paddingVertical: 8,
                            paddingHorizontal: 12,
                            backgroundColor: bg_ok,
                            borderColor: bg_ok,
                            borderRadius: 6,
                            alignItems: 'center',
                        }
                    }
                >
                    <span style={{ color: '#fff', fontSize: 16, fontWeight: 600}}>{date_human_readble(props.id?props.id:'1997-02-07')} </span>
                </div>
            </div>
        </>
    )
}



const styles = {
    imageS: {
        width: 88,
        height: 88,
        alignSelf: 'center',
        borderRadius: 7,
        marginTop: 8,
        marginBottom: 8,
    },

    noImage: {
        border: '1px solid grey',
        width: 88,
        height: 88,
        alignSelf: 'center',
        borderRadius: 7,
        marginTop: 8,
        marginBottom: 8,
    }
}