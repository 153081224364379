import axios from "axios";
import secureLocalStorage from "react-secure-storage";
import { MAPI, version_apk } from "../helper/Constant";


export const apiGetAllContactRadius = async (objData) => {
    try {

        const login = await secureLocalStorage.getItem('login');
        const password = await secureLocalStorage.getItem('password');

        const res = axios({
            method: 'post',
            url: MAPI+'/getallcontactradius',
            headers: {
              "Content-Type": "application/json", 

            },
            data: {
                email: login,
                password: password,
                curlat: objData.lat,
                curlon: objData.lon,
            }
        })

        return res;
        
    } catch (error) {
        return {
            code: 666,
            message: error,
            data: null
        }
    }
}


export async  function attendanceCheckin(login, password, locationDisplayName, lat, lon, mocked, foto) {
    try {
        const response = await axios({
            method: 'post',
            url: MAPI+'/checkin',
            headers: {
              "Content-Type": "application/json", 
            },
            data: {
              email: login,
              password: password,
              x_ci_location: locationDisplayName,
              check_in_latitude: lat,
              check_in_longitude: lon,
              x_mocked: mocked,
              x_ci_image: foto,
              version: version_apk
            }
        })

        if (response.data.code == "200") {
            alert(response.data.message)
            return response.data;
        } else {
            return response.data;
        }
        
    } catch (error) {
        alert("Error check in")
        console.log(error);
        return error;
    }
}

export  async function attendanceCheckout(login, password, locationDisplayName, lat, lon, mocked, foto) {
    try {
        const response = await axios({
            method: 'post',
            url: MAPI+'/checkout',
            headers: {
            "Content-Type": "application/json", 
            },
            data: {
            email: login,
            password: password,
            x_co_location: locationDisplayName,
            check_out_latitude: lat,
            check_out_longitude: lon,
            x_mocked: mocked,
            x_co_image: foto,
            version: version_apk
            }
        })

        if (response.data.code == "200") {
            alert(response.data.message)
            return response.data;
        } else {
            return response.data;
        }
    } catch (error) {
        alert("Error check out")
        console.log(error);
        return error;
    }
}
